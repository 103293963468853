<template>
 <b-link>
      <div class="item-heading">
        <h5 class="text-truncate mb-0">
          {{ package.name }}
        </h5>

          <div class="demo-inline-spacing">
                <li
                  v-for="countries in package.info"
                  :key="countries"
                  class="d-inline-block"
                  :class="{'selected': selectedColor === color}"
                  @click="selectedColor = color"
                >
                  <b-avatar
                    size="sm"
                    :src="countries.country_flag"
                  />
                </li>
              </div>

      </div>
      <div class="img-container w-50 mx-auto py-75">
        <b-img
          class="package-image"
          :src="package.info[0].image"
          fluid
        />
      </div>
      <div class="item-meta">
        <p class="card-text text-primary mb-0">
          ${{ package.info[0].sale_price }}
        </p>
      </div>
</b-link>
</template>

<script>
import {
  BAvatar, BCardBody, BCardText, BImg, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCardBody,
    BCardText,
    BAvatar,
    BImg,
    BLink,
  },
  props: ['package'],
}

</script>

<style lang="scss">
.package-image{
  border-radius: 20px;
}
</style>
